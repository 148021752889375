<template>
  <b-card-body>

    <table class="table table-bordered table-hover">
      <!-- <tr>
        <td colspan="4"><label style="font-size:20px;" class="form-check-label" for="isResultShown">Would like to show result?</label></td>
        <td><b-form-checkbox id="isResultShown"  @change="statusChange(employee.id)" v-model="employee.is_result_shown" :value="1" switch /></td>
      </tr> -->
      <tr>
        <td>Exam Name</td>
        <td>Exam Year</td>
        <td>Result</td>
        <td>Board/University </td>
        <td>Action </td>
      </tr>
      <tr v-for="(val,key) in educational_qualifications" :key="key">
        <td>{{ val.name_of_exam}}</td>
        <td>{{ val.exam_year }} </td>
        <td>{{ val.result }} </td>
        <td>{{ val.board_or_university }} </td>
        <td>
          <b-button  @click="selectRow(val)"
                     variant="primary">
            <feather-icon class="text-body " icon="EditIcon" size="15" />
          </b-button>
        </td>
      </tr>
    </table>

    <h4 class="mt-5">Add New/Update</h4>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="ed_q_form">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Exam Name') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('exam name')"
                  rules="required"
              >
                <b-form-input
                    v-model="name_of_exam"
                    name="name_of_exam"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('SSC')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Exam Year') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('exam year')"
                  rules="required"
              >
                <b-form-input
                    v-model="exam_year"
                    name="exam_year"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('2012')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Result') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('result')"
                  rules="required"
              >
                <b-form-input
                    v-model="result"
                    name="result"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('5.00')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Board/University') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('board/university')"
                  rules="required"
              >
                <b-form-input
                    v-model="board_or_university"
                    name="board_or_university"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Rajshahi')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col>
            <b-button
                variant="danger"
                type="button" @click="clear()"
            >
              Clear
            </b-button>
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-body>
</template>
<script>
import {
  BCardBody,BButton, BFormGroup, BFormInput,
  BForm,BRow, BCol,BCardText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {required} from '@validations'
import apiCall from "@/libs/axios";
export default {
  name:'EducationalExperience',
  components:{
    BCardBody,ValidationProvider, ValidationObserver,
    BButton, BFormGroup, BFormInput,
    BForm,BRow, BCol,BCardText,
  },
  props:{
    employee:{
      type:Object,
      required:true,
    }
  },
  data(){
    return{
      model_mode:'add',
      required,
      selected_row:{},
      name_of_exam:'',
      exam_year:'',
      result:'',
      board_or_university:'',
    }
  },
  methods:{
    clear(){
      this.model_mode='add';
      this.selected_row={};
      this.name_of_exam='';
      this.exam_year='';
      this.result='';
      this.board_or_university='';
    },
    selectRow(row){
      this.selected_row=row;
      this.name_of_exam=row.name_of_exam;
      this.exam_year=row.exam_year;
      this.result=row.result;
      this.board_or_university=row.board_or_university;
      this.model_mode='edit';
    },
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.ed_q_form);
          if (this.model_mode ==='add'){
            apiCall.post(`/employee/education/qualification/store${this.employee.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.clear();
                this.$refs.simpleRules.reset();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_EMPLOYEE_EDUCATIONAL_QUALIFICATION');
              }else this.$toaster.error(response.data.message);
            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/employee/education/qualification/update${this.selected_row.id}`,data).then((response)=>{
              if (response.data.status==='success'){
                this.selected_row={};
                this.clear();
                this.$refs.simpleRules.reset();
                this.$toaster.success(response.data.message);
                this.$store.dispatch('GET_ALL_EMPLOYEE_EDUCATIONAL_QUALIFICATION');
              }else this.$toaster.error(response.data.message);

            }).catch((error)=>{
              if (error.response.status ==422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                })
              }
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    }
  },
  computed: {
    educational_qualifications(){
      return this.$store.getters.educational_qualifications.filter(item=>parseInt(item.employee_id)===parseInt(this.employee.id));
    }
  },
}
</script>